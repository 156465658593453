// Header.js
import React from 'react';

const Header = ({ handleSearch }) => {
  return (
    <header>
      <h1>Celebrity List</h1>
      {/* <input
        type="text"
        placeholder="Search by name"
        onChange={handleSearch}
      /> */}
    </header>
  );
};

export default Header;
